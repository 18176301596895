import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SkinConditionsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="#FFC8C7"
      />
      <path
        d="M17.8357 19.2572C18.11 19.2572 18.3324 19.0348 18.3324 18.7606C18.3324 18.4863 18.11 18.2639 17.8357 18.2639C17.5614 18.2639 17.3391 18.4863 17.3391 18.7606C17.3391 19.0348 17.5614 19.2572 17.8357 19.2572Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.499136"
      />
      <path
        d="M14.9753 21.3563C15.2496 21.3563 15.472 21.134 15.472 20.8597C15.472 20.5854 15.2496 20.363 14.9753 20.363C14.701 20.363 14.4787 20.5854 14.4787 20.8597C14.4787 21.134 14.701 21.3563 14.9753 21.3563Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.499136"
      />
      <path
        d="M17.8357 23.3329C18.11 23.3329 18.3324 23.1105 18.3324 22.8362C18.3324 22.562 18.11 22.3396 17.8357 22.3396C17.5614 22.3396 17.3391 22.562 17.3391 22.8362C17.3391 23.1105 17.5614 23.3329 17.8357 23.3329Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.499136"
      />
      <path
        d="M16.8542 20.8595C17.1284 20.8595 17.3508 20.6371 17.3508 20.3629C17.3508 20.0886 17.1284 19.8662 16.8542 19.8662C16.5799 19.8662 16.3575 20.0886 16.3575 20.3629C16.3575 20.6371 16.5799 20.8595 16.8542 20.8595Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.499136"
      />
      <path
        d="M18.8274 21.3563C19.1017 21.3563 19.324 21.134 19.324 20.8597C19.324 20.5854 19.1017 20.363 18.8274 20.363C18.5531 20.363 18.3308 20.5854 18.3308 20.8597C18.3308 21.134 18.5531 21.3563 18.8274 21.3563Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.499136"
      />
      <path
        d="M20.0653 19.7565C20.3396 19.7565 20.562 19.5341 20.562 19.2598C20.562 18.9855 20.3396 18.7632 20.0653 18.7632C19.791 18.7632 19.5687 18.9855 19.5687 19.2598C19.5687 19.5341 19.791 19.7565 20.0653 19.7565Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.499136"
      />
      <path
        d="M21.2072 13.6381C21.1648 13.6381 21.1223 13.6306 21.0799 13.6156C20.8852 13.5457 20.7854 13.3311 20.8553 13.1364L22.475 8.63672C22.5698 8.37717 22.5548 8.09516 22.4375 7.84559C22.3202 7.59602 22.1106 7.40635 21.8511 7.31151C21.5915 7.21917 21.3095 7.23165 21.0599 7.34895C20.8104 7.46624 20.6207 7.67588 20.5259 7.93543L18.5668 13.3735C18.4969 13.5682 18.2822 13.668 18.0876 13.5981C17.8929 13.5282 17.7931 13.3136 17.863 13.119L19.8221 7.68087C19.9843 7.23165 20.3087 6.87477 20.7405 6.67012C21.1722 6.46797 21.6564 6.44551 22.1031 6.60523C22.5523 6.76745 22.9092 7.09189 23.1139 7.52364C23.316 7.9554 23.3385 8.43956 23.1788 8.88629L21.5591 13.386C21.5042 13.5382 21.3594 13.6331 21.2072 13.6331V13.6381Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M23.0143 16.9396C22.9719 16.9396 22.9295 16.9322 22.8871 16.9172C22.6924 16.8473 22.5926 16.6327 22.6624 16.438L24.4793 11.3942C24.6715 10.8577 24.392 10.2637 23.8554 10.069C23.3188 9.87687 22.7248 10.1564 22.5302 10.693L21.0527 14.7984C20.9829 14.993 20.7682 15.0928 20.5736 15.023C20.3789 14.9531 20.2791 14.7385 20.3489 14.5438L21.8264 10.4384C22.1583 9.5125 23.1841 9.03084 24.1099 9.36526C25.0358 9.69719 25.5175 10.7229 25.1831 11.6488L23.3662 16.6926C23.3113 16.8448 23.1666 16.9396 23.0143 16.9396Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M18.5891 28.0984C18.372 28.0984 18.1549 28.061 17.9427 27.9861L12.839 26.1493C12.1328 25.8947 11.6386 25.2509 11.5787 24.5121C11.5263 23.8658 11.3167 22.9299 10.9498 21.7245C10.8974 21.5423 9.75437 17.4094 10.6503 15.6899L12.5021 12.3707C12.9264 11.6095 13.8298 11.2701 14.6509 11.5671L15.6716 11.9339C15.8663 12.0038 15.9661 12.2184 15.8962 12.4131C15.8264 12.6077 15.6117 12.7076 15.4171 12.6377L14.3963 12.2708C13.9222 12.1011 13.4006 12.2958 13.156 12.735L11.3092 16.0468C10.6828 17.2472 11.3541 20.4018 11.6686 21.5148C12.0479 22.7627 12.2675 23.7535 12.3249 24.4522C12.3599 24.899 12.6618 25.2908 13.0936 25.4455L18.1973 27.2823C18.6664 27.4521 19.1955 27.2998 19.51 26.9055C20.361 25.8448 21.821 24.592 21.9832 24.4522C24.147 22.261 24.7235 19.0666 24.7285 19.0341C24.7335 19.0117 24.7384 18.9917 24.7459 18.9717L26.5628 13.928C26.7549 13.3914 26.4754 12.7974 25.9389 12.6028C25.4023 12.4081 24.8083 12.6901 24.6137 13.2267L24.1495 14.5144C24.0796 14.7091 23.865 14.8089 23.6703 14.7391C23.4756 14.6692 23.3758 14.4546 23.4457 14.2599L23.9099 12.9721C24.2418 12.0462 25.2675 11.5646 26.1934 11.899C26.6426 12.0612 26.9995 12.3856 27.2042 12.8174C27.4063 13.2491 27.4288 13.7333 27.2691 14.18L25.4622 19.1963C25.3923 19.5757 24.7459 22.7327 22.5073 24.9913C22.4998 24.9988 22.4923 25.0063 22.4823 25.0138C22.4674 25.0263 20.935 26.329 20.0965 27.3747C19.7221 27.8414 19.1631 28.0984 18.5916 28.0984H18.5891Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M13.6174 18.0208C13.5749 18.0208 13.5325 18.0133 13.4901 17.9983C13.2954 17.9284 13.1956 17.7138 13.2655 17.5191L16.5323 8.44235C16.6945 7.99312 17.019 7.63624 17.4507 7.4316C17.8825 7.22945 18.3667 7.20699 18.8134 7.36671C19.2626 7.52893 19.6195 7.85337 19.8241 8.28512C20.0263 8.71687 20.0487 9.20103 19.889 9.64776C19.8191 9.84242 19.6045 9.94225 19.4098 9.87237C19.2152 9.80249 19.1154 9.58786 19.1852 9.3932C19.2801 9.13365 19.2651 8.85164 19.1478 8.60207C19.0305 8.3525 18.8209 8.16283 18.5613 8.068C18.3018 7.97566 18.0197 7.98813 17.7702 8.10543C17.5206 8.22273 17.3309 8.43236 17.2361 8.69192L13.9693 17.7687C13.9144 17.9209 13.7696 18.0158 13.6174 18.0158V18.0208Z"
        fill="#C21300"
        stroke="#C21300"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
