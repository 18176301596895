import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function VomitingAndDiarrhoeaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="#BEEDE9"
      />
      <path
        d="M10.0769 29.0366C9.92721 29.0366 9.78246 28.9468 9.72506 28.797C8.67687 26.1516 9.7026 24.789 10.4064 24.215C10.9055 23.8082 11.6617 23.8057 12.1633 24.21C13.6533 25.4129 17.6988 27.9186 23.0595 25.2357C26.9153 23.3065 28.7072 19.845 27.8562 15.9767C27.1349 12.6999 24.7141 10.1743 22.2284 10.0994C20.0522 10.0395 19.316 10.8656 18.6022 11.6692C17.9683 12.3854 17.3119 13.1242 15.822 13.1242C15.3503 13.1242 14.9311 12.9345 14.5742 12.5626C12.8946 10.8032 13.1441 5.10555 13.1541 4.86347C13.1641 4.65383 13.3413 4.49161 13.5509 4.50409C13.7606 4.51408 13.9203 4.69127 13.9103 4.90091C13.8379 6.45821 13.9103 10.7732 15.1207 12.041C15.3304 12.2607 15.56 12.368 15.8195 12.368C16.9675 12.368 17.4367 11.8389 18.0332 11.1676C18.7844 10.3215 19.7203 9.26585 22.2484 9.34321C25.071 9.42807 27.7988 12.2108 28.5924 15.8145C29.5258 20.0447 27.5816 23.8182 23.3964 25.9145C17.6438 28.792 13.2914 26.0942 11.6867 24.8015C11.4621 24.6193 11.1077 24.6193 10.8856 24.8015C10.2217 25.3455 9.60028 26.4311 10.4288 28.52C10.5062 28.7147 10.4114 28.9343 10.2167 29.0117C10.1718 29.0291 10.1244 29.0391 10.0769 29.0391V29.0366Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M19.8317 18.0753C19.1279 18.0753 18.232 17.8058 17.5856 16.84C17.2911 16.4007 17.0041 16.3109 16.485 16.1462C15.8685 15.9515 15.0225 15.687 13.8221 14.6638C10.6351 11.9435 11.1417 5.13525 11.1642 4.84824C11.1817 4.63861 11.3638 4.48388 11.571 4.50134C11.7806 4.51881 11.9354 4.701 11.9179 4.90814C11.9129 4.97552 11.4212 11.619 14.3112 14.0872C15.3919 15.0107 16.1206 15.2403 16.7071 15.4224C17.2736 15.5996 17.7653 15.7544 18.2095 16.4157C19.053 17.6735 20.3233 17.2518 20.3757 17.2318C20.5729 17.1644 20.7875 17.2667 20.8574 17.4639C20.9273 17.6611 20.825 17.8757 20.6303 17.9456C20.4306 18.0179 20.1486 18.0728 19.8267 18.0728L19.8317 18.0753Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M8.52816 29.3353C8.3884 29.3353 8.25363 29.2579 8.18874 29.1232C8.16878 29.0832 6.31449 25.175 9.39416 22.27C10.7568 20.9848 12.2592 21.4764 13.255 21.8008C13.6293 21.9231 14.0935 22.0754 14.2333 21.993C14.2807 21.9656 14.3256 21.8607 14.3506 21.7135C14.6426 20.0364 16.3696 19.4699 17.1358 19.3002C17.1233 18.3443 17.6973 17.8701 17.7247 17.8477C17.887 17.7154 18.1265 17.7429 18.2563 17.9051C18.3861 18.0673 18.3611 18.3019 18.2039 18.4342C18.1789 18.4541 17.7971 18.8035 17.9169 19.5497C17.9344 19.6521 17.9069 19.7569 17.8445 19.8392C17.7821 19.9216 17.6898 19.974 17.5875 19.9865C17.565 19.989 15.3688 20.2785 15.0943 21.8433C15.0269 22.2301 14.8722 22.4921 14.6201 22.6419C14.1859 22.9039 13.6443 22.7242 13.0154 22.5196C12.0446 22.2026 10.9465 21.8433 9.91076 22.8191C7.24288 25.3372 8.80018 28.649 8.86757 28.7887C8.95741 28.9759 8.88005 29.203 8.69287 29.2954C8.64046 29.3203 8.58306 29.3328 8.52816 29.3328V29.3353Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M24.9807 15.9242C24.836 15.9242 24.6987 15.8394 24.6363 15.6996C23.4384 12.9619 21.382 12.8096 21.2946 12.8046C21.09 12.7921 20.9303 12.6174 20.9427 12.4103C20.9527 12.2057 21.1274 12.0484 21.3321 12.0559C21.4369 12.0609 23.9301 12.2156 25.3226 15.3976C25.405 15.5873 25.3201 15.8069 25.1305 15.8918C25.0806 15.9142 25.0307 15.9242 24.9807 15.9242Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M21.3169 20.9266C21.1746 20.9266 21.0374 20.8443 20.975 20.7045C20.8901 20.5148 20.975 20.2952 21.1646 20.2104C21.6064 20.0132 22.0531 19.7836 22.4923 19.5291C22.672 19.4242 22.8991 19.4866 23.0039 19.6638C23.1088 19.8435 23.0464 20.0706 22.8692 20.1754C22.4075 20.4425 21.9383 20.6846 21.4716 20.8942C21.4217 20.9167 21.3693 20.9266 21.3194 20.9266H21.3169Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M19.1845 25.4485C17.9716 25.4485 17.2504 25.2139 17.208 25.199C17.0108 25.1341 16.906 24.9219 16.9709 24.7248C17.0358 24.5301 17.2479 24.4228 17.4426 24.4877C17.4426 24.4877 18.6555 24.8745 20.5522 24.595C20.7568 24.5626 20.9465 24.7073 20.9764 24.912C21.0064 25.1166 20.8641 25.3063 20.6595 25.3362C20.1104 25.4161 19.6138 25.446 19.1796 25.446L19.1845 25.4485Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
