import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HeadacheIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="#E3F1FF"
      />
      <path
        d="M11.6065 13.0173C11.4493 13.0173 11.3021 12.915 11.2497 12.7577C11.1873 12.5606 11.2946 12.3509 11.4917 12.286L13.3835 11.6796L12.6198 9.84527C12.5798 9.74794 12.5823 9.63813 12.6248 9.5433C12.6697 9.44846 12.7496 9.37609 12.8494 9.34364L15.1279 8.6149C15.3251 8.55251 15.5347 8.65983 15.5996 8.85698C15.6645 9.05414 15.5547 9.26378 15.3575 9.32867L13.4658 9.93512L14.2295 11.7694C14.2694 11.8668 14.2669 11.9766 14.2245 12.0714C14.1796 12.1663 14.0997 12.2386 13.9999 12.2711L11.7213 13.0023C11.6839 13.0148 11.644 13.0198 11.6065 13.0198V13.0173Z"
        fill="#83C5FF"
        stroke="#83C5FF"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M14.4332 14.2123C14.276 14.2123 14.1287 14.11 14.0763 13.9528C14.0139 13.7556 14.1212 13.546 14.3184 13.4811L16.2101 12.8747L15.4464 11.0403C15.4065 10.943 15.409 10.8357 15.4514 10.7384C15.4963 10.6435 15.5762 10.5712 15.676 10.5387L17.9546 9.80997C18.1518 9.74758 18.3614 9.85489 18.4263 10.0521C18.4887 10.2492 18.3814 10.4588 18.1842 10.5237L16.2925 11.1302L17.0561 12.9645C17.0961 13.0618 17.0936 13.1692 17.0512 13.2665C17.0062 13.3613 16.9264 13.4337 16.8265 13.4661L14.548 14.1974C14.5105 14.2099 14.4706 14.2148 14.4332 14.2148V14.2123Z"
        fill="#83C5FF"
        stroke="#83C5FF"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M24.5279 28.1657C24.5279 28.1657 24.5229 28.1657 24.5204 28.1657C24.3108 28.1608 24.146 27.9886 24.1485 27.7789C24.2034 24.8066 25.022 23.1444 25.8107 21.5372C26.7415 19.6405 27.6225 17.8486 27.1384 13.9229C26.2549 6.77777 19.0773 6.31108 19.0049 6.30609C18.995 6.30609 18.9875 6.30609 18.9775 6.30359C18.955 6.3011 16.7988 6.03656 14.5102 6.71538C11.5004 7.60634 9.68607 9.64032 9.11706 12.7549C9.10957 12.7849 8.85501 13.9054 9.44399 14.4021C9.5538 14.4944 9.68608 14.6616 9.70605 14.9436C9.77094 15.8895 8.286 17.6864 7.39505 18.6722C7.38506 18.6847 7.38257 18.6946 7.38257 18.6971C7.41501 18.782 7.54978 19.0191 8.08386 19.2662C9.17946 19.7728 9.08462 21.2652 9.00476 21.8342C9.3267 22.1586 9.96809 22.9523 9.73599 23.978C9.59125 24.6194 9.47144 25.3406 9.85079 25.7749C10.1927 26.1642 10.9589 26.3164 12.0694 26.2141C12.1019 26.2141 14.6575 26.0195 17.1831 24.9788C17.3778 24.8989 17.5974 24.9912 17.6772 25.1834C17.7571 25.3756 17.6648 25.5977 17.4726 25.6776C14.8297 26.7682 12.2392 26.9603 12.1293 26.9678C10.7368 27.0951 9.80337 26.868 9.27928 26.2715C8.61293 25.5128 8.8625 24.4048 8.99477 23.8108C9.18694 22.9523 8.36586 22.2734 8.35588 22.266C8.24607 22.1761 8.19367 22.0314 8.22361 21.8916C8.22611 21.8766 8.53557 20.3093 7.7644 19.9525C7.18041 19.6829 6.81106 19.346 6.66631 18.9492C6.56898 18.6847 6.63136 18.3827 6.82852 18.1631C8.45071 16.3687 9.0397 15.2157 8.94237 14.9686C8.00399 14.1849 8.35588 12.6651 8.37085 12.6002C9.82833 4.60903 18.653 5.50498 19.0549 5.55239C19.4392 5.57985 26.9462 6.22373 27.8846 13.8306C28.3987 17.9809 27.4254 19.96 26.487 21.8716C25.7333 23.4065 24.9546 24.9912 24.9022 27.7939C24.8972 28.001 24.73 28.1657 24.5229 28.1657H24.5279Z"
        fill="#83C5FF"
        stroke="#83C5FF"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M14.4445 28.166C14.2548 28.166 14.0901 28.0237 14.0676 27.8316L13.9029 26.399C13.878 26.1919 14.0277 26.0022 14.2348 25.9798C14.442 25.9548 14.6317 26.1046 14.6541 26.3117L14.8188 27.7442C14.8438 27.9514 14.694 28.141 14.4869 28.1635C14.4719 28.1635 14.457 28.166 14.442 28.166H14.4445Z"
        fill="#83C5FF"
        stroke="#83C5FF"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
