import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function GeneralDoctorConsultIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="#BEEDE9"
      />
      <path
        d="M19.5411 25.0418H16.7983C16.5887 25.0418 16.419 24.8721 16.419 24.6625V22.0595H13.816C13.6064 22.0595 13.4367 21.8898 13.4367 21.6801V18.9374C13.4367 18.7277 13.6064 18.558 13.816 18.558H16.419V15.955C16.419 15.7454 16.5887 15.5757 16.7983 15.5757H19.5411C19.7507 15.5757 19.9204 15.7454 19.9204 15.955V18.558H22.5234C22.733 18.558 22.9028 18.7277 22.9028 18.9374V21.6801C22.9028 21.8898 22.733 22.0595 22.5234 22.0595H19.9204V24.6625C19.9204 24.8721 19.7507 25.0418 19.5411 25.0418ZM17.1777 24.2831H19.1617V21.6801C19.1617 21.4705 19.3314 21.3008 19.5411 21.3008H22.1441V19.3167H19.5411C19.3314 19.3167 19.1617 19.147 19.1617 18.9374V16.3344H17.1777V18.9374C17.1777 19.147 17.008 19.3167 16.7983 19.3167H14.1954V21.3008H16.7983C17.008 21.3008 17.1777 21.4705 17.1777 21.6801V24.2831Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M28.9877 27.9886H7.35514C6.60643 27.9886 6 27.3796 6 26.6334V13.1193C6 12.3706 6.60893 11.7642 7.35514 11.7642H28.9877C29.7364 11.7642 30.3429 12.3731 30.3429 13.1193V26.6334C30.3429 27.3821 29.7339 27.9886 28.9877 27.9886ZM7.35514 12.5204C7.02571 12.5204 6.7562 12.7899 6.7562 13.1193V26.6334C6.7562 26.9628 7.02571 27.2324 7.35514 27.2324H28.9877C29.3172 27.2324 29.5867 26.9628 29.5867 26.6334V13.1193C29.5867 12.7899 29.3172 12.5204 28.9877 12.5204H7.35514Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M28.9877 18.3577H24.9896C24.78 18.3577 24.6103 18.188 24.6103 17.9784C24.6103 17.7688 24.78 17.5991 24.9896 17.5991H28.9877C29.3172 17.5991 29.5867 17.3295 29.5867 17.0001V13.1168C29.5867 12.7874 29.3172 12.5179 28.9877 12.5179H7.35514C7.02571 12.5179 6.7562 12.7874 6.7562 13.1168V17.0001C6.7562 17.3295 7.02571 17.5991 7.35514 17.5991H11.3532C11.5629 17.5991 11.7326 17.7688 11.7326 17.9784C11.7326 18.188 11.5629 18.3577 11.3532 18.3577H7.35514C6.60643 18.3577 6 17.7488 6 17.0026V13.1193C6 12.3706 6.60893 11.7642 7.35514 11.7642H28.9877C29.7364 11.7642 30.3429 12.3731 30.3429 13.1193V17.0026C30.3429 17.7513 29.7339 18.3577 28.9877 18.3577Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M13.5202 12.5205C13.3105 12.5205 13.1408 12.3508 13.1408 12.1412V10.1072C13.1408 9.22125 13.862 8.5 14.748 8.5H21.5961C22.4821 8.5 23.2033 9.22125 23.2033 10.1072V10.7461C23.2033 10.9557 23.0337 11.1255 22.8241 11.1255C22.6144 11.1255 22.4447 10.9557 22.4447 10.7461V10.1072C22.4447 9.63803 22.0628 9.25619 21.5936 9.25619H14.7455C14.2763 9.25619 13.8945 9.63803 13.8945 10.1072V12.1412C13.8945 12.3508 13.7248 12.5205 13.5152 12.5205H13.5202Z"
        fill="#02635E"
        stroke="#02635E"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
