import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ClipBoardHealthIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.165 8.08422V4.12653C12.165 3.68937 11.8106 3.33499 11.3735 3.33499H9.79039M3.45808 11.2504L3.45808 12.8335C3.45808 13.2706 3.81246 13.625 4.24962 13.625H11.3735C11.8106 13.625 12.165 13.2706 12.165 12.8335V11.2504M5.8327 3.33499H4.24962C3.81246 3.33499 3.45808 3.68937 3.45808 4.12653L3.45808 8.08422M1.875 9.6673H4.91873C4.99373 9.66733 5.06233 9.62506 5.09604 9.55807L6.02267 7.70429C6.05795 7.63349 6.132 7.59051 6.21097 7.59499C6.28994 7.59947 6.35866 7.65054 6.38572 7.72487L7.64374 11.1855C7.67041 11.263 7.74214 11.316 7.82407 11.3189C7.90601 11.3217 7.9812 11.2737 8.01312 11.1981L9.22682 8.46733C9.25797 8.3974 9.32666 8.35172 9.4032 8.35002C9.47973 8.34833 9.55038 8.39093 9.58459 8.45941L10.1334 9.55807C10.1671 9.62506 10.2357 9.66733 10.3107 9.6673H13.7481M8.97511 2.54345C8.81113 2.04664 8.33385 1.72197 7.81154 1.75191C7.29019 1.72319 6.81434 2.04764 6.65062 2.54345H6.22847C6.00989 2.54345 5.8327 2.72064 5.8327 2.93922V4.5223C5.8327 4.74088 6.00989 4.91807 6.22847 4.91807H9.39462C9.6132 4.91807 9.79039 4.74088 9.79039 4.5223V2.93922C9.79039 2.72064 9.6132 2.54345 9.39462 2.54345H8.97511Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
